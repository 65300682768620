// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries
// import { getFirestore } from "firebase/firestore";

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCBZZblsHJ1pWBL9fj7qxya_Qkm_zOJPRc",
//   authDomain: "fir-test-fba8d.firebaseapp.com",
//   projectId: "fir-test-fba8d",
//   storageBucket: "fir-test-fba8d.appspot.com",
//   messagingSenderId: "693055712658",
//   appId: "1:693055712658:web:68e6fabfc653744940a54b"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// export const db = getFirestore(app);





// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA8Y2wo7C1Rg0rTORAVU04ZhlJlIKAOOow",
  authDomain: "test2-20f89.firebaseapp.com",
  projectId: "test2-20f89",
  storageBucket: "test2-20f89.appspot.com",
  messagingSenderId: "470253096968",
  appId: "1:470253096968:web:58598b7f322a41db7a81bd",
  measurementId: "G-XLXYL743WR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);
