import React, { useState, useEffect } from "react";
import {
    doc,
    addDoc,
    deleteDoc,
    collection,
    serverTimestamp,
    getDocs,
    runTransaction,
    query,
    orderBy,
} from "firebase/firestore";
import { db } from "../services/firebase.config";
import TodoList from './TodoList';

const Todo = () => {
    const collectionRef = collection(db, "todo");

    const [createTodo, setCreateTodo] = useState("");
    const [filterTodo, setFilterTodo] = useState("");
    const [todos, setTodos] = useState([]);

    const submitTodo = async (e) => {
        e.preventDefault();

        try {
            const docRef = await addDoc(collectionRef, {
                todo: createTodo,
                isChecked: false,
                timestamp: serverTimestamp(),
            });

            setTodos([
                ...todos,
                {
                    id: docRef.id,
                    todo: createTodo,
                    isChecked: false,
                    timestamp: serverTimestamp(),
                },
            ]);
        } catch (error) {
            console.log(error);
        }

        e.target.reset();
    };

    const deleteHandler = async (id) => {
        try {
            const docRef = doc(db, "todo", id);
            await deleteDoc(docRef);
        } catch (error) {
            console.log(error);
        }

        const updatedTodos = [...todos];

        const selectedTodo = updatedTodos.find((key) => key.id === id);

        updatedTodos.splice(updatedTodos.indexOf(selectedTodo), 1);

        setTodos(updatedTodos);
    };

    const checkHandler = async (e, id) => {
        try {
            const docRef = doc(db, "todo", id);

            await runTransaction(db, async (transaction) => {
                const todoDoc = await transaction.get(docRef);

                if (todoDoc.exists()) {
                    const newValue = !todoDoc.data().isChecked;
                    transaction.update(docRef, { isChecked: newValue });
                }
            });
        } catch (error) {
            console.log(error);
        }

        const updatedTodos = [...todos];

        const selectedTodo = updatedTodos.find((key) => key.id === id);

        selectedTodo.isChecked = !selectedTodo.isChecked;

        setTodos(updatedTodos);
    };

    const filterHandler = (type) => {
        setFilterTodo(type);
    }

    useEffect(() => {
        const getTodo = async () => {
            const q = query(collectionRef, orderBy("timestamp"));
            await getDocs(q)
                .then((todo) => {
                    let todoData = todo.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                    }));

                    setTodos(todoData);
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        getTodo();
    }, []);

    return (
        <div>
            <h1 className="text-2xl font-bold mb-4">Todo list:</h1>
            <div className="flex justify-evenly">
                <div onClick={() => filterHandler('all')} className="cursor-pointer rounded-md hover:bg-green-700 bg-green-500 text-white px-2 py-1 flex justify-center min-w-[60px]">All</div>
                <div onClick={() => filterHandler('active')} className="cursor-pointer rounded-md hover:bg-green-700 bg-green-500 text-white px-2 py-1 flex justify-center min-w-[60px]">Active</div>
                <div onClick={() => filterHandler('completed')} className="cursor-pointer rounded-md hover:bg-green-700 bg-green-500 text-white px-2 py-1 flex justify-center min-w-[60px]">Completed</div>
            </div>
            <hr className="my-4" />
            <TodoList 
                todos={todos} 
                checkHandler={checkHandler}
                deleteHandler={deleteHandler}
                filterTodos={filterTodo}
            />
            <hr className="my-4" />
            <form className="flex gap-4" onSubmit={submitTodo}>
                <input
                    onChange={(e) => setCreateTodo(e.target.value)}
                    name="todoNew"
                    type="text"
                    placeholder="Your task..."
                />
                <button type="submit" className="p-4 bg-lime-600">
                    Add
                </button>
            </form>
        </div>
    );
};

export default Todo;
