import './App.css';
import Todo from './components/Todo';

function App() {
  return (
    <div className="App">
        <div className="container my-8">
            <h1 className="text-3xl underline font-bold mb-4">Puck ❤️ Leon</h1>
        </div>
        <div className="container p-4 drop-shadow-lg bg-[#fafafa] rounded-lg">
            <Todo/>
        </div>
    </div>
  );
}

export default App;
