import React from 'react';

const TodoList = (props) => {
    const type = props.filterTodos;
    console.log('hello from TodoList');
    console.log(props.filterTodos);

    const oldTodos = [...props.todos];
    let filteredTodos = oldTodos;

    if(type === 'completed') {
        console.log('completed!')
        filteredTodos = filteredTodos.filter((todo) => todo.isChecked);
    } else if (type === 'active') {
        console.log('active!');
        filteredTodos = filteredTodos.filter((todo) => !todo.isChecked);
    } 

    //     console.log('Todo:', todo.todo,todo.isChecked);
    //     // return todo.isChecked === true
    // });

    return(
        <ul className="flex flex-col gap-4">
        {filteredTodos.map(({ todo, id, isChecked }) => (
            <li className="flex flex-row gap-2 items-center" key={id}>
                <input
                    type="checkbox"
                    defaultChecked={isChecked}
                    name={id}
                    onChange={(e) => props.checkHandler(e, id)}
                />
                <span
                    className={
                        isChecked ? "line-through text-slate-400" : ""
                    }
                >
                    {todo}
                </span>
                <span
                    className="ml-auto cursor-pointer"
                    onClick={() => props.deleteHandler(id)}
                >
                    &times;
                </span>
            </li>
        ))}
    </ul>

    );
}

export default TodoList;